import { ViewProvider } from '../../../components/use-view'
import { Form } from './form'
import { Table } from './table'

export enum View {
  TABLE,
  FORM,
}

export const UserView = () => {
  return (
    <ViewProvider
      children={{
        [View.TABLE]: Table,
        [View.FORM]: Form,
      }}
    />
  )
}
