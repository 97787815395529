import { Box, CloseButton, Text, useToast } from '@chakra-ui/react'

const Notification = (props: { title: string; onClose: any }) => {
  const { title, onClose } = props

  return (
    <Box
      bg="teal.600"
      color="white"
      py={3}
      px={4}
      rounded="lg"
      display="flex"
      justifyContent="space-between"
      gap={2}
      mt={4}
    >
      <Text fontSize="sm">{title}</Text>
      <CloseButton
        // icon={<CloseIcon />}
        onClick={onClose}
        aria-label="알림 닫기"
        fontSize="2xs"
        size="xs"
      />
    </Box>
  )
}
export const useNotification = () => {
  const toast = useToast()

  return (title: string) => {
    toast({
      // title: `데이터프레임, "${props.data.name}", 이 업데이트됬습니다!`,
      render: (props) => <Notification {...props} title={title} />,
      status: 'success',
      duration: 8000,
      isClosable: true,
    })
  }
}
