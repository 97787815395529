import { SearchIcon } from '@chakra-ui/icons'
import { Box, CloseButton, Input, Spinner } from '@chakra-ui/react'

export const Search = (props: {
  isLoading: boolean
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onClear: () => void
}) => {
  return (
    <Box p={4}>
      <Box
        p={2}
        rounded="md"
        bg="gray.100"
        display="flex"
        alignItems="center"
        gap={1}
      >
        {props.isLoading ? (
          <Spinner size="xs" ml={2} opacity={0.3} />
        ) : (
          <SearchIcon fontSize="xs" ml={2} opacity={0.3} />
        )}
        <Input
          placeholder="검색어를 입력해주세요..."
          size="xs"
          value={props.value}
          border="transparent"
          focusBorderColor="transparent"
          onChange={props.onChange}
        />
        {props.value && (
          <CloseButton
            size="xs"
            fontSize="2xs"
            onClick={props.onClear}
            mr={4}
            color="red.500"
          />
        )}
      </Box>
    </Box>
  )
}
