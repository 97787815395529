import { Box } from '@chakra-ui/react'
import { createContext, useContext, useState } from 'react'
import { View } from '../../routes/home/channel'

export const ViewProvider = (props: { children: any }) => {
  const { children } = props
  const [view, setView] = useState<View>(0)
  const [data, setData] = useState<any>([])
  const [value, setValue] = useState<any>(null)
  const [params, setParams] = useState<any>({ search: '', page: 1 })
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [count, setCount] = useState(0)

  const Component = children[view]
  return (
    <ViewContext.Provider
      value={{
        view,
        setView,
        data,
        setData,
        params,
        setParams,
        page,
        setPage,
        value,
        setValue,
        loaded,
        setLoaded,
        isLoading,
        setIsLoading,
        count,
        setCount,
        prevPage: () => {
          const newPage = Math.max(page - 1, 1)
          setIsLoading(true)
          setPage(newPage)
          setParams({ ...params, page: newPage })
          setLoaded(false)
        },
        nextPage: () => {
          const newPage = Math.ceil(Math.min(page + 1, count / 5))
          setIsLoading(true)
          setPage(newPage)
          setParams({ ...params, page: newPage })
          setLoaded(false)
        },
      }}
    >
      <Box color="black" h="full">
        <Component />
      </Box>
    </ViewContext.Provider>
  )
}

type ViewContextValue<DataType = any, View = any> = {
  view: number
  setView: (view: View) => void
  data: DataType[] | undefined
  setData: (data: DataType[] | undefined) => void
  value: DataType | undefined
  setValue: (data: DataType | undefined) => void
  params: { search: string; page: number }
  setParams: (params: { search: string; page: number }) => void
  page: number
  setPage: (page: number) => void
  loaded: boolean
  setLoaded: (loaded: boolean) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  count: number
  setCount: (count: number) => void
  nextPage: () => void
  prevPage: () => void
}

const ViewContext = createContext<ViewContextValue>({} as ViewContextValue)

export const useView = <DataType = any, View = any>() => {
  const context = useContext(ViewContext) as ViewContextValue<DataType, View>
  if (!context) throw new Error(`useView must be used within a ViewProvider`)

  return context
}
