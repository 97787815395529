import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box, Button, IconButton, Text } from '@chakra-ui/react'

export type ListItemProps = {
  href?: string
  onClick?: () => void
  title: string
  subtitle?: string
  actions?: {
    icon?: any
    label?: string
    onClick: () => void
    isDefault?: boolean
  }[]
}
export const ListItem = (props: ListItemProps) => {
  const { actions, title, subtitle, onClick } = props

  let defaultOnClick = onClick

  if (actions?.some((a) => a.isDefault)) {
    defaultOnClick = actions?.find((a) => a.isDefault)?.onClick
  }

  return (
    <Box
      onClick={defaultOnClick}
      p={4}
      display="flex"
      flexDir="column"
      gap={2}
      bg="gray.100"
      _hover={{
        bg: 'gray.200',
      }}
      rounded="md"
      cursor="pointer"
      userSelect="none"
      transition="ease .1s all"
      w="100%"
    >
      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDir="column" w="calc(100% - 40px)">
          <Text
            color="black"
            fontSize="sm"
            isTruncated={true}
            w="full"
            textOverflow="ellipsis"
          >
            {title}
          </Text>
          {subtitle && (
            <Text fontSize="2xs" color="black">
              {subtitle}
            </Text>
          )}
        </Box>
        {actions && (
          <Box display="flex" gap={1}>
            {actions?.map((action, index) => {
              if (action.icon) {
                return (
                  <IconButton
                    key={index}
                    size="xs"
                    icon={action.icon}
                    onClick={(e) => {
                      e.stopPropagation()
                      action.onClick()
                    }}
                    aria-label={action.label || 'action'}
                    bg={index === actions.length - 1 ? 'white' : 'white'}
                    _hover={{
                      bg: 'gray.100',
                    }}
                  />
                )
              }
              return (
                <Button
                  key={index}
                  size="xs"
                  fontSize="2xs"
                  onClick={(e) => {
                    e.stopPropagation()
                    action.onClick()
                  }}
                  aria-label="action"
                  colorScheme="gray"
                  bg="white"
                >
                  {action.label}
                </Button>
              )
            })}
          </Box>
        )}
        {!actions && (
          <IconButton
            size="xs"
            icon={<ArrowForwardIcon color="black" />}
            aria-label="action"
            bg="white"
            _hover={{
              bg: 'gray.50',
            }}
          />
        )}
      </Box>
    </Box>
  )
}
