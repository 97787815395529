import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Input,
  Select,
  Text,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useState } from 'react'
import { LABEL, NAME, setViewType, View } from '.'
import { api } from '../../../api'
import { Dataframe } from '../../../api/types'
import { ConfirmButton } from '../../../components/confirm-button'
import { useNotification } from '../../../components/use-notification'

export const Form = (props: { setView: setViewType; data?: Dataframe }) => {
  const notify = useNotification()
  const [isRemoving, setIsRemoving] = useState(false)
  const [isCreatingAgain, setIsCreatingAgain] = useState(false)
  const formik = useFormik({
    initialValues: props.data || {
      name: '',
      columns: [{ name: '', dataType: 'string', isPrimaryKey: false }],
      sampleQuestions: [{ message: '' }],
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (props.data) {
        const { error } = await api[NAME].edit(values)

        if (error) {
          formik.setErrors(error)
        } else {
          notify(`${LABEL}, "${props.data.name}", 이 업데이트됬습니다!`)
        }
      } else {
        const { error } = await api[NAME].add(values)

        if (error) {
          formik.setErrors(error)
        } else {
          if (isCreatingAgain) {
            formik.resetForm()
            setIsCreatingAgain(false)
          } else {
            props.setView(View.TABLE)
          }
          notify(`${LABEL}, "${values.name}", 이 생성됬습니다!`)
        }
      }
    },
  })

  const renderSaveText = () => {
    if (props?.data) {
      if (props.data._id) {
        return formik.isSubmitting ? '수정하는중...' : '수정하기'
      }
    }

    return formik.isSubmitting && !isCreatingAgain
      ? '저장하는중...'
      : '저장하기'
  }

  const renderReSaveText = () => {
    return formik.isSubmitting && isCreatingAgain
      ? '저장하는중...'
      : '저장하고 또 생성하기'
  }

  const isDisabled = formik.isSubmitting || isRemoving

  return (
    <Box display="flex" flexDir="column" h="100%">
      <Box as="form" onSubmit={formik.handleSubmit} h="100%">
        <>
          <Box
            display="flex"
            flexDir="column"
            gap={2}
            overflow="auto"
            p={4}
            pb={0}
            h="calc(100% - 64px)"
          >
            <Box display="flex" flexDir="column" gap={1}>
              <Text fontSize="xs" color="black" w="full">
                이름
              </Text>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder="예) 배차기록"
                name="name"
                autoComplete="off"
                color="black"
                border="none"
                bg="gray.100"
                fontSize="xs"
                isDisabled={!!props?.data?._id}
              />
              <Text fontSize="2xs" color="red.500" h="12px">
                {formik.errors.name}
              </Text>
            </Box>
            <Box display="flex" flexDir="column" gap={1}>
              <Box display="flex" justifyContent="space-between" w="full">
                <Text fontSize="xs" color="black" w="full">
                  컬럼
                </Text>
                <Text
                  fontSize="xs"
                  color="purple.500"
                  _hover={{
                    color: 'purple.800',
                  }}
                  whiteSpace="nowrap"
                  onClick={() => {
                    formik.setFieldValue('columns', [
                      ...formik.values.columns,
                      {
                        name: '',
                        dataType: 'string',
                        isPrimaryKey: false,
                      },
                    ])
                  }}
                  cursor="pointer"
                  userSelect="none"
                >
                  <AddIcon fontSize="7px" mb="2px" mr="4px" />
                  컬럼 추가하기
                </Text>
              </Box>
              <Box
                bg="gray.100"
                borderRadius="lg"
                display="flex"
                flexDir="column"
                position="relative"
                h="100%"
                overflow="auto"
              >
                <Box as="table">
                  <Box
                    as="thead"
                    position="sticky"
                    bg="gray.200"
                    top={0}
                    zIndex={2}
                  >
                    <Box
                      as="tr"
                      borderBottom="1px solid"
                      borderColor="gray.300"
                    >
                      <th>
                        <Text
                          fontSize="xs"
                          textAlign="left"
                          fontWeight={500}
                          p={2}
                        >
                          컬럼 이름
                        </Text>
                      </th>
                      <th>
                        <Text
                          fontSize="xs"
                          textAlign="left"
                          fontWeight={500}
                          p={2}
                        >
                          데이터 타입
                        </Text>
                      </th>
                      <th>
                        <Text
                          fontSize="xs"
                          textAlign="left"
                          fontWeight={500}
                          p={2}
                        >
                          고유 키
                        </Text>
                      </th>
                      <th></th>
                    </Box>
                  </Box>
                  <tbody>
                    {formik.values.columns.map((column: any, index: number) => {
                      return (
                        <Box
                          as="tr"
                          borderBottom="1px solid"
                          borderColor="gray.300"
                          bg="gray.100"
                          key={index}
                        >
                          <Box as="td" p={1} w="60%">
                            <Input
                              placeholder="예) 상차지"
                              name={`columns[${index}].name`}
                              onChange={formik.handleChange}
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  formik.setFieldValue('columns', [
                                    ...formik.values.columns,
                                    {
                                      name: '',
                                      dataType: 'string',
                                      isPrimaryKey: false,
                                    },
                                  ])
                                }
                              }}
                              onBlur={formik.handleBlur}
                              value={column.name}
                              autoComplete="off"
                              color="black"
                              border="none"
                              bg="gray.100"
                              fontSize="xs"
                              autoFocus={Object.keys(formik.touched).length > 0}
                            />
                          </Box>
                          <Box as="td" w="30%">
                            <Select
                              placeholder=""
                              name={`columns[${index}].dataType`}
                              value={column.dataType}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              color="black"
                              border="none"
                              bg="gray.100"
                              fontSize="xs"
                            >
                              <option value="string">문자열</option>
                              <option value="number">숫자</option>
                              <option value="date">날짜</option>
                            </Select>
                          </Box>
                          <Box as="td" w="10%">
                            <Box
                              w="100%"
                              h="100%"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Checkbox
                                name={`columns[${index}].isPrimaryKey`}
                                isChecked={column.isPrimaryKey}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                color="black"
                                borderColor="gray.300"
                                bg="gray.300"
                                rounded="lg"
                                size="lg"
                                onKeyUp={(e) => {
                                  if (e.key === 'Enter') {
                                    formik.setFieldValue(
                                      `columns[${index}].isPrimaryKey`,
                                      !formik.values.columns[index]
                                        .isPrimaryKey,
                                    )
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                          <Box as="td">
                            <Box
                              w="100%"
                              h="100%"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              pr={2}
                            >
                              <CloseButton
                                onClick={() => {
                                  const newColumns = formik.values.columns
                                  newColumns.splice(index, 1)
                                  formik.setFieldValue('columns', newColumns)
                                }}
                                color="red.500"
                                fontSize="10px"
                                w="24px"
                                h="24px"
                              />
                            </Box>
                          </Box>
                        </Box>
                      )
                    })}
                  </tbody>
                </Box>
              </Box>
              <Text fontSize="2xs" color="red.500" h="12px">
                {formik.errors.columns as any}
              </Text>
            </Box>
            <Box display="flex" flexDir="column" gap={1}>
              <Box display="flex" justifyContent="space-between" w="full">
                <Text fontSize="xs" color="black" w="full">
                  샘플 질문
                </Text>
                <Text
                  fontSize="xs"
                  color="purple.500"
                  _hover={{
                    color: 'purple.800',
                  }}
                  whiteSpace="nowrap"
                  onClick={() => {
                    formik.setFieldValue('sampleQuestions', [
                      ...(formik.values.sampleQuestions || []),
                      {
                        message: '',
                      },
                    ])
                  }}
                  cursor="pointer"
                  userSelect="none"
                >
                  <AddIcon fontSize="7px" mb="2px" mr="4px" />
                  질문 추가하기
                </Text>
              </Box>
              <Box
                bg="gray.100"
                borderRadius="lg"
                display="flex"
                flexDir="column"
                position="relative"
                h="100%"
                overflow="auto"
              >
                <Box as="table">
                  <Box
                    as="thead"
                    position="sticky"
                    bg="gray.200"
                    top={0}
                    zIndex={2}
                  >
                    <Box
                      as="tr"
                      borderBottom="1px solid"
                      borderColor="gray.300"
                    >
                      <th>
                        <Text
                          fontSize="xs"
                          textAlign="left"
                          fontWeight={500}
                          p={2}
                        >
                          질문
                        </Text>
                      </th>
                      <th></th>
                    </Box>
                  </Box>
                  <tbody>
                    {formik.values.sampleQuestions?.map(
                      (question: any, index: number) => {
                        return (
                          <Box
                            as="tr"
                            borderBottom="1px solid"
                            borderColor="gray.300"
                            bg="gray.100"
                            key={index}
                          >
                            <Box as="td" p={1} w="full">
                              <Input
                                placeholder="예) 이번달 수입이 얼마야?"
                                name={`sampleQuestions[${index}].message`}
                                onChange={formik.handleChange}
                                onKeyUp={(e) => {
                                  if (e.key === 'Enter') {
                                    formik.setFieldValue('sampleQuestions', [
                                      ...formik.values.sampleQuestions,
                                      {
                                        message: '',
                                      },
                                    ])
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                value={question.name}
                                autoComplete="off"
                                color="black"
                                border="none"
                                bg="gray.100"
                                fontSize="xs"
                                autoFocus={
                                  Object.keys(formik.touched).length > 0
                                }
                              />
                            </Box>
                            <Box as="td">
                              <Box
                                w="100%"
                                h="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                pr={2}
                              >
                                <CloseButton
                                  onClick={() => {
                                    const sampleQuestions =
                                      formik.values.sampleQuestions
                                    sampleQuestions.splice(index, 1)
                                    formik.setFieldValue(
                                      'sampleQuestions',
                                      sampleQuestions,
                                    )
                                  }}
                                  color="red.500"
                                  fontSize="10px"
                                  w="24px"
                                  h="24px"
                                />
                              </Box>
                            </Box>
                          </Box>
                        )
                      },
                    )}
                  </tbody>
                </Box>
              </Box>
              <Text fontSize="2xs" color="red.500" h="12px">
                {formik.errors.columns as any}
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={4}
            gap={2}
          >
            <Button
              size="sm"
              onClick={() => props.setView(View.TABLE)}
              w="fit-content"
              colorScheme="gray"
              bg="white"
              isDisabled={isDisabled}
            >
              뒤로가기
            </Button>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={2}
            >
              {props.data?._id && (
                <ConfirmButton
                  isDisabled={isDisabled}
                  onClick={() => {
                    if (props.data?._id) {
                      setIsRemoving(true)
                      api[NAME].remove({ _id: props.data._id }).then(() => {
                        props.setView(View.TABLE)
                        setIsRemoving(false)

                        notify(
                          `${LABEL}, "${props?.data?.name}", 이 삭제됬습니다.`,
                        )
                      })
                    }
                  }}
                  alertTitle={`${LABEL}, "${props?.data?.name}", 을 삭제할까요?`}
                  alertLabel={`${LABEL}을 삭제하면, ${LABEL}에 저장된 모든 데이터가 삭제됩니다.`}
                >
                  {isRemoving ? '삭제중...' : '삭제하기'}
                </ConfirmButton>
              )}
              {!props.data?._id && (
                <Button
                  onClick={() => {
                    setIsCreatingAgain(true)
                    formik.submitForm()
                  }}
                  size="sm"
                  w="fit-content"
                  colorScheme="purple"
                  isDisabled={isDisabled}
                >
                  {renderReSaveText()}
                </Button>
              )}
              <Button
                onClick={formik.submitForm}
                size="sm"
                w="fit-content"
                colorScheme="purple"
                isDisabled={isDisabled}
              >
                {renderSaveText()}
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </Box>
  )
}
