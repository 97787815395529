import { Box, Button, Input, Text } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useState } from 'react'
import { LABEL, NAME, setViewType, View } from '.'
import { api } from '../../../api'
import { Channel } from '../../../api/types'
import { ConfirmButton } from '../../../components/confirm-button'
import { MultiSelect } from '../../../components/multi-select'
import { useNotification } from '../../../components/use-notification'

export const Form = (props: { setView: setViewType; data?: Channel }) => {
  const notify = useNotification()
  const [isRemoving, setIsRemoving] = useState(false)
  const [isCreatingAgain, setIsCreatingAgain] = useState(false)
  const formik = useFormik({
    initialValues: props.data || {
      name: '',
      dataframe: [],
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (props.data) {
        const { error } = await api[NAME].edit(values)

        if (error) {
          formik.setErrors(error)
        } else {
          notify(`${LABEL}, "${props.data.name}", 이 업데이트됬습니다!`)
        }
      } else {
        const { error } = await api[NAME].add(values)

        if (error) {
          formik.setErrors(error)
        } else {
          if (isCreatingAgain) {
            formik.resetForm()
            setIsCreatingAgain(false)
          } else {
            props.setView(View.TABLE)
          }
          notify(`${LABEL}, "${values.name}", 이 생성됬습니다!`)
        }
      }
    },
  })

  const renderSaveText = () => {
    if (props?.data) {
      if (props.data._id) {
        return formik.isSubmitting ? '수정하는중...' : '수정하기'
      }
    }

    return formik.isSubmitting && !isCreatingAgain
      ? '저장하는중...'
      : '저장하기'
  }

  const renderReSaveText = () => {
    return formik.isSubmitting && isCreatingAgain
      ? '저장하는중...'
      : '저장하고 또 생성하기'
  }

  const isDisabled = formik.isSubmitting || isRemoving

  return (
    <Box display="flex" flexDir="column" h="100%">
      <Box as="form" onSubmit={formik.handleSubmit} h="100%">
        <>
          <Box
            display="flex"
            flexDir="column"
            gap={2}
            overflow="auto"
            p={4}
            pb={0}
            h="calc(100% - 64px)"
          >
            <Box display="flex" flexDir="column" gap={1}>
              <Text fontSize="xs" color="black" w="full">
                이름
              </Text>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder="예) 운영관리팀"
                name="name"
                autoComplete="off"
                color="black"
                border="none"
                bg="gray.100"
                fontSize="xs"
              />
              <Text fontSize="2xs" color="red.500" h="12px">
                {formik.errors.name}
              </Text>
            </Box>
            <Box display="flex" flexDir="column" gap={1}>
              <Text fontSize="xs" color="black" w="full">
                데이터프레임
              </Text>
              <MultiSelect
                label="데이터프레임"
                onQuery={async (query) => {
                  const { data } = await api.dataframe.list({ search: query })
                  return data
                }}
                onChange={(value) => {
                  formik.setFieldValue('dataframe', value)
                }}
                onBlur={formik.handleBlur}
                value={formik.values.dataframe}
              />
              <Text fontSize="2xs" color="red.500" h="12px">
                {formik.errors.dataframe as any}
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={4}
            gap={2}
          >
            <Button
              size="sm"
              onClick={() => props.setView(View.TABLE)}
              w="fit-content"
              colorScheme="gray"
              bg="white"
              isDisabled={isDisabled}
            >
              뒤로가기
            </Button>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={2}
            >
              {props.data?._id && (
                <ConfirmButton
                  isDisabled={isDisabled}
                  onClick={() => {
                    if (props.data?._id) {
                      setIsRemoving(true)
                      api[NAME].remove({ _id: props.data._id }).then(() => {
                        props.setView(View.TABLE)
                        setIsRemoving(false)

                        notify(
                          `${LABEL}, "${props?.data?.name}", 이 삭제됬습니다.`,
                        )
                      })
                    }
                  }}
                  alertTitle={`${LABEL}, "${props?.data?.name}", 을 삭제할까요?`}
                  alertLabel={`${LABEL}을 삭제하면, ${LABEL}에 저장된 모든 데이터가 삭제됩니다.`}
                >
                  {isRemoving ? '삭제중...' : '삭제하기'}
                </ConfirmButton>
              )}
              {!props.data?._id && (
                <Button
                  onClick={() => {
                    setIsCreatingAgain(true)
                    formik.submitForm()
                  }}
                  size="sm"
                  w="fit-content"
                  colorScheme="purple"
                  isDisabled={isDisabled}
                >
                  {renderReSaveText()}
                </Button>
              )}
              <Button
                onClick={formik.submitForm}
                size="sm"
                w="fit-content"
                colorScheme="purple"
                isDisabled={isDisabled}
              >
                {renderSaveText()}
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </Box>
  )
}
