import { View } from '.'
import { api } from '../../../api'
import { History } from '../../../api/types'
import { useView } from '../../../components/use-view'
import { TableDefault } from '../../../components/view-parts'

export const Table = () => {
  const {
    params,
    setData,
    setIsLoading,
    loaded,
    setLoaded,
    setCount,
    setView,
    setValue,
  } = useView<History>()

  return (
    <TableDefault<History>
      request={() => {
        if (!loaded) {
          setIsLoading(true)
          api.history.list(params).then(({ data, count }) => {
            setLoaded(true)
            setCount(count)
            setData(data)
            setIsLoading(false)
          })
        }
      }}
      handleListItemProps={(item) => {
        return {
          key: item._id,
          title: `Q: ${item.question}`,
          subtitle: item.user,
          onClick: () => {
            setValue(item)
            setView(View.PAGE)
          },
        }
      }}
    />
  )
}
