import { ViewProvider } from '../../../components/use-view'
import { Page } from './page'
import { Table } from './table'

export enum View {
  TABLE,
  FORM,
  PAGE,
}

export const HistoryView = () => {
  return (
    <ViewProvider
      children={{
        [View.TABLE]: Table,
        [View.PAGE]: Page,
      }}
    />
  )
}
