import { useState } from 'react'
import {
  AuthCheckAuthFunctionParams,
  AuthPromptFunctionReturn,
  AuthsendPasscodeParams,
  AuthVerifyPasscodeFunctionParams,
  AuthVerifyPasscodeFunctionReturn,
  ChannelAddFunctionParams,
  ChannelAddFunctionReturn,
  ChannelEditFunctionParams,
  ChannelEditFunctionReturn,
  ChannelListFunctionParams,
  ChannelListFunctionReturn,
  ChannelRemoveFunctionParams,
  ChannelRemoveFunctionReturn,
  ChatInitFunctionParams,
  ChatInitFunctionReturn,
  ChatPromptFunctionParams,
  ChatPromptFunctionReturn,
  DataframeAddFunctionParams,
  DataframeAddFunctionReturn,
  DataframeEditFunctionParams,
  DataframeEditFunctionReturn,
  DataframeListFunctionParams,
  DataframeListFunctionReturn,
  DataframeRemoveFunctionParams,
  DataframeRemoveFunctionReturn,
  HistoryListFunctionParams,
  HistoryListFunctionReturn,
  UserAddFunctionParams,
  UserAddFunctionReturn,
  UserEditFunctionParams,
  UserEditFunctionReturn,
  UserListFunctionParams,
  UserListFunctionReturn,
  UserRemoveFunctionParams,
  UserRemoveFunctionReturn,
} from './types'

const call = async <ReturnType>(
  url: string,
  method: 'POST' | 'GET' | 'PATCH' | 'DELETE',
  params: any,
): Promise<ReturnType> => {
  const { token } = JSON.parse(
    localStorage.getItem('chamchi-current-user') || '{}',
  )

  let response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
    method: method,
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
  })

  const data = await response.json()
  return data
}

export const useAPI = <ReturnType>(): {
  data: ReturnType | null
  setData: (data: ReturnType) => void
  call: any
  status: string | null
  error: unknown | null
} => {
  const [data, setData] = useState<ReturnType | null>(null)
  const [status, setStatus] = useState<string | null>(null)
  const [error, setError] = useState<unknown | null>(null)

  const call: any = async (callback: () => any) => {
    setStatus('loading')
    try {
      const response = await callback()
      setStatus('loaded')
      setData(response)
    } catch (error) {
      setStatus('error')
      setError(error)
    }
  }

  return {
    data,
    setData,
    call,
    status,
    error,
  }
}

export const api = {
  chat: {
    init: async (params: ChatInitFunctionParams) =>
      await call<ChatInitFunctionReturn>('chat/init', 'POST', params),
    prompt: async (params: ChatPromptFunctionParams) =>
      await call<ChatPromptFunctionReturn>('chat/prompt', 'POST', params),
  },
  auth: {
    checkAuth: async (params: AuthCheckAuthFunctionParams) =>
      await call<AuthPromptFunctionReturn>('auth/check-auth', 'POST', params),
    sendPasscode: async (params: AuthsendPasscodeParams) =>
      await call<AuthPromptFunctionReturn>(
        'auth/get-passcode-link',
        'POST',
        params,
      ),
    verifyPasscode: async (params: AuthVerifyPasscodeFunctionParams) =>
      await call<AuthVerifyPasscodeFunctionReturn>(
        'auth/verify-passcode',
        'POST',
        params,
      ),
  },
  dataframe: {
    add: async (params: DataframeAddFunctionParams) =>
      await call<DataframeAddFunctionReturn>('dataframe/add', 'POST', params),
    edit: async (params: DataframeEditFunctionParams) =>
      await call<DataframeEditFunctionReturn>('dataframe/edit', 'POST', params),
    list: async (params: DataframeListFunctionParams) =>
      await call<DataframeListFunctionReturn>('dataframe/list', 'POST', params),
    remove: async (params: DataframeRemoveFunctionParams) =>
      await call<DataframeRemoveFunctionReturn>(
        'dataframe/remove',
        'POST',
        params,
      ),
  },
  user: {
    add: async (params: UserAddFunctionParams) =>
      await call<UserAddFunctionReturn>('user/add', 'POST', params),
    edit: async (params: UserEditFunctionParams) =>
      await call<UserEditFunctionReturn>('user/edit', 'POST', params),
    list: async (params: UserListFunctionParams) =>
      await call<UserListFunctionReturn>('user/list', 'POST', params),
    remove: async (params: UserRemoveFunctionParams) =>
      await call<UserRemoveFunctionReturn>('user/remove', 'POST', params),
  },
  channel: {
    add: async (params: ChannelAddFunctionParams) =>
      await call<ChannelAddFunctionReturn>('channel/add', 'POST', params),
    edit: async (params: ChannelEditFunctionParams) =>
      await call<ChannelEditFunctionReturn>('channel/edit', 'POST', params),
    list: async (params: ChannelListFunctionParams) =>
      await call<ChannelListFunctionReturn>('channel/list', 'POST', params),
    remove: async (params: ChannelRemoveFunctionParams) =>
      await call<ChannelRemoveFunctionReturn>('channel/remove', 'POST', params),
  },
  history: {
    list: async (params: HistoryListFunctionParams) =>
      await call<HistoryListFunctionReturn>('history/list', 'POST', params),
  },
}
