import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { Combobox, Transition } from '@headlessui/react'
import { debounce } from 'lodash'
import { Fragment, useEffect, useRef, useState } from 'react'
import './index.css'

const MultiSelectTag = ({ children, onRemove }: any) => {
  return (
    <Tag size="md" borderRadius="full" variant="solid" colorScheme="purple">
      <TagLabel fontSize="xs" mt={0.5}>
        {children}
      </TagLabel>
      <TagCloseButton
        fontSize="xs"
        ml={0}
        onClick={(e) => {
          e.stopPropagation()
          onRemove()
        }}
      />
    </Tag>
  )
}

export const MultiSelect = (props: {
  label: string
  onChange: (value: any) => void
  onBlur: any
  value: any
  onQuery: (query: string) => Promise<any>
}) => {
  const {
    label,
    onChange = () => {},
    onBlur = () => {},
    value = [],
    onQuery,
  } = props

  const [options, setOptions] = useState<any>([])
  const [selected, setSelected] = useState(value || [])
  const [query, setQuery] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    onChange(selected)
  }, [selected])

  useEffect(() => {
    const call = debounce(async () => {
      const data = await onQuery(query)
      setOptions(data)
    }, 100)

    call()

    return call.cancel
  }, [query])

  const multiple: any = true
  return (
    <Box position="relative">
      <Combobox value={value} onChange={setSelected} multiple={multiple}>
        <Box
          bg="gray.100"
          display="flex"
          p={3}
          px={4}
          fontSize="xs"
          rounded="md"
        >
          <Box
            display="flex"
            gap={1}
            flexWrap="wrap"
            w="full"
            cursor="text"
            onClick={() => {
              inputRef.current?.focus()
            }}
          >
            {value.map((option: any, key: any) => (
              <MultiSelectTag
                key={key}
                onRemove={() => {
                  setSelected((currentSelectedItems: any) =>
                    currentSelectedItems.filter((currentSelected: any) => {
                      return currentSelected._id !== option._id
                    }),
                  )
                }}
              >
                {option.name}
              </MultiSelectTag>
            ))}
            <Combobox.Input
              onBlur={onBlur}
              ref={inputRef}
              placeholder={`${label} 이름을 입력하세요`}
              style={{
                padding: '0 8px',
                background: 'transparent',
                outline: 'none',
                flexGrow: 1,
                height: 24,
              }}
              onChange={(event) => setQuery(event.target.value)}
            />
          </Box>
          {options.filter((a: any) => {
            return !selected.find((b: any) => b._id === a._id)
          }).length > 0 && (
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon aria-hidden="true" fontSize="xl" />
            </Combobox.Button>
          )}
        </Box>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Box
            bg="white"
            shadow="md"
            p={0}
            mt={1}
            fontSize="xs"
            position="absolute"
            w="100%"
            rounded="md"
            zIndex={10}
          >
            <Combobox.Options style={{ padding: 0 }}>
              {options.length === 0 && query !== '' ? (
                <Box p={2}>"{query}"를(을) 찾지 못했습니다</Box>
              ) : (
                options
                  .filter((a: any) => {
                    return !selected.find((b: any) => b._id === a._id)
                  })
                  .map((option: any, key: any) => {
                    return (
                      <Combobox.Option
                        as="div"
                        key={key}
                        className={({ active }) => {
                          return `${active ? 'multi-select-active' : ''}`
                        }}
                        value={option}
                      >
                        {({ selected, active }) => (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={2}
                            px={4}
                            rounded="md"
                            cursor="pointer"
                          >
                            <span>{option.name}</span>
                            {selected ? (
                              <span>
                                <CheckIcon fontSize="xs" aria-hidden="true" />
                              </span>
                            ) : null}
                          </Box>
                        )}
                      </Combobox.Option>
                    )
                  })
              )}
            </Combobox.Options>
          </Box>
        </Transition>
      </Combobox>
    </Box>
  )
}
