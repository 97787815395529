import { Box, Button } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { User } from '../../api/types'
import Logo from '../logo'

export const Header = () => {
  const [currentUser, setCurrentUser] = useState<User>()

  useEffect(() => {
    const user = localStorage.getItem('chamchi-current-user')

    if (user) setCurrentUser(JSON.parse(user) as User)
  }, [])

  return (
    <Box
      as="header"
      px={4}
      py={5}
      bg="gray.800"
      position="sticky"
      top={0}
      left={0}
      w="full"
      h="56px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box minW="72px">
        <Logo />
      </Box>
      <Box
        w="1600px"
        mx="auto"
        display="flex"
        gap={3}
        justifyContent="space-between"
      >
        {/* <Box display="flex" gap={3}>
        <Button size="sm">관리자 대쉬보드</Button>
        <Button
          size="sm"
          variant="outline"
          color="whiteAlpha.700"
          borderColor="whiteAlpha.700"
        >
          운영관리 1팀
        </Button>
        <Button
          size="sm"
          variant="outline"
          color="whiteAlpha.700"
          borderColor="whiteAlpha.700"
        >
          운영관리 2팀
        </Button>
      </Box> */}
        {/* <Button size="sm" bg="purple.500" color="white">
        대쉬보드 만들기
      </Button> */}
      </Box>
      <Box
        minW="72px"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        {/* <Avatar size="xs" bg="transparent" variant="outline" /> */}
        {currentUser && (
          <Button
            size="sm"
            fontSize="xs"
            bg="whiteAlpha.50"
            _hover={{ bg: 'whiteAlpha.100' }}
            _active={{ bg: 'whiteAlpha.100' }}
            color="white"
            onClick={() => {
              localStorage.removeItem('chamchi-current-user')
              document.location.href = '/'
            }}
          >
            로그아웃
          </Button>
        )}
      </Box>
    </Box>
  )
}
