import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, IconButton, Skeleton, Text } from '@chakra-ui/react'
import { debounce } from 'lodash'
import { ReactNode, useEffect, useMemo } from 'react'
import { Search } from '../../routes/home/search'
import { ListItem, ListItemProps } from '../list-item'
import { useView } from '../use-view'

export const ViewContainer = (props: { children: ReactNode }) => {
  return (
    <Box display="flex" flexDir="column" h="100%">
      {props.children}
    </Box>
  )
}

export const ViewBody = (props: {
  children: ReactNode
  isCentered?: boolean
  isPadded?: boolean
}) => {
  if (props.isCentered) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        h="100%"
        gap={3}
        px={4}
        pb={12}
      >
        {props.children}
      </Box>
    )
  }
  return (
    <Box
      h="100%"
      display="flex"
      flexDir="column"
      overflow="auto"
      gap={3}
      pt={props.isPadded ? 4 : 0}
      px={4}
    >
      {props.children}
    </Box>
  )
}

export const ViewFooter = (props: { left?: ReactNode; right?: ReactNode }) => {
  const { left, right } = props
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={4}
      gap={2}
    >
      <Box display="flex" alignItems="center" gap={2}>
        {left || <Box />}
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        {right}
      </Box>
    </Box>
  )
}

export const TableNavigation = (props: { unit?: string }) => {
  const { unit = '개' } = props
  const { page, count, prevPage, nextPage } = useView()

  const start = (page - 1) * 5 + 1
  const end = Math.min(page * 5, count)

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        icon={<ChevronLeftIcon fontSize="md" />}
        size="sm"
        onClick={prevPage}
        w="fit-content"
        bg="white"
        aria-label="이전 페이지"
        isDisabled={page === 1}
      />
      <IconButton
        icon={<ChevronRightIcon fontSize="md" />}
        size="sm"
        onClick={nextPage}
        w="fit-content"
        bg="white"
        aria-label="다음 페이지"
        isDisabled={page === Math.ceil(count / 5)}
      />
      <Text fontSize="xs" ml={2} userSelect="none">
        {count}
        {unit}중 {start}-{end}
      </Text>
    </Box>
  )
}

export const TableSearch = () => {
  const { params, setParams, setPage, setLoaded, isLoading } = useView()

  return (
    <Search
      isLoading={isLoading}
      value={params.search}
      onClear={() => {
        setPage(1)
        setParams({ ...params, page: 1, search: '' })
        setLoaded(false)
      }}
      onChange={(e) => {
        setPage(1)
        setParams({ ...params, page: 1, search: e.target.value })
        setLoaded(false)
      }}
    />
  )
}

export const TableDefault = <DataType = any,>(props: {
  request: () => void
  labels?: {
    noDataViaSearch: string
    noData: string
  }
  handleListItemProps: (item: DataType, key: number) => ListItemProps
  actions?: ReactNode
}) => {
  const { params, data, isLoading } = useView()

  useEffect(() => {
    const call = debounce(props.request, 500)
    call()

    return call.cancel
  }, [params])

  const noData = !data || data.length === 0
  const noDataText = useMemo(() => {
    if (params.search)
      return props.labels?.noDataViaSearch || `찾을려는 데이터가 없습니다.`

    return props.labels?.noData || `데이터가 없습니다.`
  }, [])

  if (isLoading)
    return (
      <ViewContainer>
        <TableSearch />
        <ViewBody>
          <Skeleton opacity={0.2} minH="68px" h="68px" w="100%" rounded="md" />
          <Skeleton opacity={0.2} minH="68px" h="68px" w="100%" rounded="md" />
        </ViewBody>
        <ViewFooter left={<TableNavigation />} />
      </ViewContainer>
    )

  if (noData)
    return (
      <ViewContainer>
        <TableSearch />
        <ViewBody isCentered>
          <Text fontSize="sm">{noDataText}</Text>
        </ViewBody>
      </ViewContainer>
    )
  return (
    <ViewContainer>
      <TableSearch />
      <ViewBody>
        {data.map((item, key) => {
          return <ListItem {...props.handleListItemProps(item, key)} />
        })}
      </ViewBody>
      <ViewFooter left={<TableNavigation />} right={props.actions} />
    </ViewContainer>
  )
}
