import { Text } from '@chakra-ui/react'
import { Link } from 'wouter'

export default function Logo() {
  return (
    <Link href="/">
      <Text
        fontWeight="black"
        fontSize="xl"
        letterSpacing={1}
        color="white"
        cursor="pointer"
        userSelect="none"
      >
        참치AI
      </Text>
    </Link>
  )
}
