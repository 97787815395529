import {
  ChakraProvider,
  defineStyleConfig,
  extendTheme,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { Route } from 'wouter'
import { api } from './api'
import { AuthUser } from './api/types'
import { Header } from './components/header'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Dashboard from './routes/channel'
import Home from './routes/home'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

export const Auth = (props: { children: any }) => {
  const { children } = props
  const currentUserRaw = localStorage.getItem('chamchi-current-user')
  let currentUser: AuthUser | { token: '' } = { token: '' }
  const [isAuthorized, setIsAuthorized] = useState(false)

  if (currentUserRaw) currentUser = JSON.parse(currentUserRaw)

  useEffect(() => {
    const get = async () => {
      const { token } = JSON.parse(
        localStorage.getItem('chamchi-current-user') || '{}',
      )

      if (!token) {
        document.location.href = '/'
        return
      }

      const authChecked = await api.auth.checkAuth({ token: currentUser.token })

      if (authChecked) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
        document.location.href = '/'
      }
    }

    get()
  }, [])

  if (!isAuthorized) return

  return children
}

const Router = () => {
  return (
    <div style={{ height: '100%' }}>
      <Header />
      <div style={{ height: 'calc(100% - 56px)' }}>
        <Route path="/:_id">
          <Auth>
            <Dashboard />
          </Auth>
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </div>
    </div>
  )
}

const theme = extendTheme({
  config: {
    darkMode: 'light',
    useSystemColorMode: false,
  },
  components: {
    Input: defineStyleConfig({
      variants: {
        filled: {
          field: {
            color: 'black',
            bg: 'gray.100',
            _placeholder: {
              color: 'blackAlpha.700',
            },
            _selected: {
              color: 'black',
              bg: 'gray.100',
            },
            _internal: {
              color: 'black',
              bg: 'gray.100',
            },
            _hover: {
              bg: 'gray.100',
            },
            _active: {
              bg: 'gray.100',
            },
            _autofill: {
              bg: 'gray.100',
              color: 'black !important',
              boxShadow: '0 0 0px 1000px #232323 inset',
              borderColor: 'blue.500',
            },
          },
        },
      },
      defaultProps: {
        // variant: 'filled',
      },
    }),
    Button: defineStyleConfig({
      baseStyle: {
        // borderRadius: '0',
        // color: 'black',
      },
      variants: {
        // filled: {
        //   // bg: 'blue.500',
        //   // color: 'black',
        //   // _hover: {
        //   //   bg: 'blue.600',
        //   // },
        //   // _active: {
        //   //   bg: 'blue.700',
        //   // },
        // },
      },
      defaultProps: {
        // variant: 'filled',
      },
    }),
  },
})

root.render(
  <React.StrictMode>
    <ChakraProvider
      theme={theme}
      toastOptions={{ defaultOptions: { position: 'top' } }}
    >
      <Router />
    </ChakraProvider>
  </React.StrictMode>,
)

reportWebVitals()
