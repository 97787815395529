import {
  Box,
  Button,
  Input,
  SlideFade,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { api } from '../../api'
import { User } from '../../api/types'
import { ChannelView } from './channel'
import { DatabaseView } from './database'
import { HistoryView } from './history'
import './index.css'
import { UserView } from './user'

let interval: any = 0
const maxTime = 1000 * 60 * 10
function Home() {
  const [email, setEmail] = useState('')
  const [passcode, setPasscode] = useState('')
  const [step, setStep] = useState(-1)
  const [error, setError] = useState('')
  const [timer, setTimer] = useState(0)
  const [currentUser, setCurrentUser] = useState<User>()
  const [triggerTimer, setTriggerTimer] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  useEffect(() => {
    const user = localStorage.getItem('chamchi-current-user')

    setTimeout(() => {
      if (user) {
        setCurrentUser(JSON.parse(user) as User)
        setStep(2)
      } else {
        setStep(0)
      }
    }, 800)
  }, [])

  useEffect(() => {
    if (triggerTimer > 0) {
      clearInterval(interval)
      interval = setInterval(() => {
        setTimer((prev) => {
          const newTimer = prev + 1000

          if (newTimer >= maxTime) {
            clearInterval(interval)
            setTriggerTimer(0)
            return maxTime
          }

          return newTimer
        })
      }, 1000)
    }
  }, [triggerTimer])

  // timer to mm:ss
  const remainingTime = maxTime - timer
  const isMaxTime = remainingTime <= 0
  const minutes = Math.floor(remainingTime / 60000)
  const seconds = ((remainingTime % 60000) / 1000).toFixed(0)
  const time = `${minutes}:${parseInt(seconds) < 10 ? '0' : ''}${seconds}`

  async function sendPasscode() {
    setIsLoading(true)
    await api.auth.sendPasscode({ email })
    setIsLoading(false)

    setTimer(0)
    setStep(1)
    setTriggerTimer(1)
  }

  async function comparePasscode() {
    setIsLoading(true)
    const temp = await api.auth.verifyPasscode({ email, passcode })
    setIsLoading(false)

    setTimer(0)
    setStep(1)
    setTriggerTimer(1)

    return temp
  }

  function renderTitle() {
    switch (step) {
      case 2:
        return undefined
      default:
        return undefined
    }
  }

  function renderStep() {
    switch (step) {
      case -1:
        return (
          <>
            <Text
              fontSize="sm"
              fontWeight={500}
              color="black"
              w="full"
              textAlign="center"
            >
              로딩중..
            </Text>
          </>
        )
      case 0:
        return (
          <Box
            bg="white"
            rounded="md"
            mx="auto"
            my="auto"
            maxW="400px"
            p={6}
            display="flex"
            flexDir="column"
            as="form"
            onSubmit={async (e) => {
              e.preventDefault()
              setError('')
              if (!email) {
                setError('이메일을 입력해주세요')
                return
              }

              await sendPasscode()
            }}
            autoComplete="off"
          >
            <Text fontSize="sm" color="black" w="full" textAlign="center">
              로그인을 하기위해 보안코드를 받을 이메일을 입력해주세요
            </Text>
            <Input
              value={email}
              placeholder="이메일"
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              autoComplete="off"
              color="black"
              border="none"
              bg="gray.100"
              mt={2}
              type="email"
              fontSize="xs"
            />
            <Button
              size="md"
              colorScheme="purple"
              color="white"
              mt={2}
              fontSize="xs"
              type="submit"
              w="full"
              isLoading={isLoading}
              loadingText="보안코드 발송중..."
            >
              보내기
            </Button>
          </Box>
        )
      case 1:
        return (
          <Box
            bg="white"
            rounded="md"
            mx="auto"
            my="auto"
            maxW="400px"
            p={6}
            display="flex"
            flexDir="column"
            as="form"
            onSubmit={async (e) => {
              e.preventDefault()
              setError('')
              if (isMaxTime) {
                await sendPasscode()
              } else {
                if (!passcode) {
                  setError('보안코드를 입력해주세요')
                  return
                } else {
                  const response = await comparePasscode()

                  if (response.status === 'success') {
                    localStorage.setItem(
                      'chamchi-current-user',
                      JSON.stringify(response.data),
                    )
                    setStep(2)
                  } else {
                    setError('보안코드가 일치하지 않습니다')
                  }
                }
              }
            }}
          >
            <Text
              fontSize="sm"
              fontWeight={500}
              color="black"
              w="full"
              textAlign="center"
              // maxW="208px"
              mx="auto"
            >
              <b>{email}</b> 으로 보안코드를 보냈습니다. 아래에 입력해주세요.{' '}
            </Text>

            <Input
              placeholder={isMaxTime ? '보안코드가 만료되었습니다' : '보안코드'}
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              name="passcode"
              mt={2}
              border="none"
              color="black"
              bg="gray.100"
              fontSize="xs"
              disabled={isMaxTime}
            />
            {isMaxTime ? (
              <Button
                w="full"
                size="md"
                colorScheme="purple"
                mt={2}
                fontSize="xs"
                type="submit"
                loadingText="보안코드 재발송중입니다..."
                isLoading={isLoading}
              >
                보안코드 재발송
              </Button>
            ) : (
              <Button
                w="full"
                size="md"
                colorScheme="purple"
                mt={2}
                fontSize="xs"
                type="submit"
                loadingText="로그인중입니다..."
                isLoading={isLoading}
              >
                로그인하기 ({time})
              </Button>
            )}
          </Box>
        )
      case 2:
        return (
          <>
            <Tabs
              index={tabIndex}
              onChange={handleTabsChange}
              maxH="600px"
              maxW="720px"
              mx="auto"
              w="100%"
              h="100%"
              my="auto"
              bg="white"
              rounded="lg"
              colorScheme="purple"
            >
              <TabList h="54px">
                <Tab
                  fontSize="sm"
                  px={4}
                  py={3}
                  color={tabIndex === 0 ? 'black' : 'gray.500'}
                  borderTopLeftRadius="lg"
                  fontWeight={600}
                >
                  나의 채널
                </Tab>
                <Tab
                  fontSize="sm"
                  px={4}
                  py={3}
                  color={tabIndex === 1 ? 'black' : 'gray.500'}
                  fontWeight={600}
                >
                  데이터프레임
                </Tab>
                <Tab
                  fontSize="sm"
                  px={4}
                  py={3}
                  color={tabIndex === 2 ? 'black' : 'gray.500'}
                  fontWeight={600}
                >
                  팀원
                </Tab>
                <Tab
                  fontSize="sm"
                  px={4}
                  py={3}
                  color={tabIndex === 3 ? 'black' : 'gray.500'}
                  fontWeight={600}
                >
                  기록
                </Tab>
              </TabList>
              <TabPanels h="calc(100% - 54px)">
                <TabPanel p={0} h="100%">
                  <ChannelView />
                </TabPanel>
                <TabPanel p={0} h="100%">
                  <DatabaseView />
                </TabPanel>
                <TabPanel p={0} h="100%">
                  <UserView />
                </TabPanel>
                <TabPanel p={0} h="100%">
                  <HistoryView />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )
    }
  }

  return (
    <Box display="flex" flexDir="column" height="100%">
      <Box
        bg="gray.800"
        height="100%"
        display="flex"
        flexDir="column"
        gap={4}
        color="white"
        justifyContent="center"
        alignItems="center"
      >
        {step === -1 ? (
          <></>
        ) : (
          <SlideFade in={step !== -1} style={{ height: '100%', width: '100%' }}>
            <Box
              h="100%"
              mx="auto"
              w="100%"
              rounded="md"
              display="flex"
              flexDir="column"
              alignItems="center"
            >
              <Text fontWeight={600} opacity={0.7}>
                {renderTitle()}
              </Text>
              <Box
                // bg="white"
                mx="auto"
                h="100%"
                w="100%"
                rounded="md"
                display="flex"
                flexDir="column"
              >
                {renderStep()}
                {error && (
                  <Text color="red.500" fontSize="2xs" mt={2}>
                    {error}
                  </Text>
                )}
              </Box>
            </Box>
          </SlideFade>
        )}
      </Box>
    </Box>
  )
}

export default Home
