import { Box } from '@chakra-ui/react'
import { useState } from 'react'
import { Channel } from '../../../api/types'
import { Form } from './form'
import { Table } from './table'

export const NAME = 'channel'
export const LABEL = '채널'
export enum View {
  TABLE,
  FORM,
}
export type setViewType = (viewIndex: number, data?: any) => void

export const ChannelView = () => {
  const [view, setView] = useState<View>(0)
  const [data, setData] = useState<Channel | Channel[] | undefined>()

  const renderView = () => {
    switch (view) {
      case View.TABLE:
        return (
          <Table
            setView={(viewIndex, data) => {
              setView(viewIndex)
              setData(data)
            }}
          />
        )
      case View.FORM:
        return (
          <Form
            data={data as Channel}
            setView={(viewIndex, data) => {
              setView(viewIndex)
              setData(data)
            }}
          />
        )
    }
  }

  return (
    <Box color="black" h="100%">
      {renderView()}
    </Box>
  )
}
