import {
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'
import { Box, Button, IconButton, Skeleton, Text } from '@chakra-ui/react'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { FaRegPenToSquare } from 'react-icons/fa6'
import { useLocation } from 'wouter'
import { LABEL, NAME, setViewType, View } from '.'
import { api } from '../../../api'
import { Channel } from '../../../api/types'
import { ListItem } from '../../../components/list-item'
import { Search } from '../search'

export const Table = (props: { setView: setViewType }) => {
  const [data, setData] = useState<Channel[]>([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const noData = data.length === 0
  const [isLoading, setIsLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [params, setParams] = useState<any>({ search: '' })
  const [, setLocation] = useLocation()

  useEffect(() => {
    const call = debounce(async () => {
      if (!loaded) {
        setIsLoading(true)

        api[NAME].list(params).then(({ data, count }) => {
          setLoaded(true)
          setCount(count)
          setData(data)
          setIsLoading(false)
        })
      }
    }, 500)

    call()

    return call.cancel
  }, [params])

  const renderNoDataText = () => {
    if (params.search) {
      return `찾을려는 ${LABEL}이 없습니다. 새로운 ${LABEL}을 만들어보세요`
    }

    return `${LABEL} 없습니다. 새로운 ${LABEL}을 만들어보세요`
  }

  const pageNavigation = () => {
    const start = (page - 1) * 5 + 1
    const end = Math.min(page * 5, count)
    return (
      <Box display="flex" alignItems="center">
        <IconButton
          icon={<ChevronLeftIcon fontSize="md" />}
          size="sm"
          onClick={() => {
            const newPage = Math.max(page - 1, 1)

            setPage(newPage)
            setParams({ ...params, page: newPage })
            setLoaded(false)
          }}
          w="fit-content"
          isDisabled={page === 1}
          bg="white"
          aria-label="이전 페이지"
        />
        <IconButton
          icon={<ChevronRightIcon fontSize="md" />}
          size="sm"
          onClick={() => {
            const newPage = Math.ceil(Math.min(page + 1, count / 5))
            setPage(newPage)
            setParams({ ...params, page: newPage })
            setLoaded(false)
          }}
          w="fit-content"
          isDisabled={page === Math.ceil(count / 5)}
          bg="white"
          aria-label="다음 페이지"
        />
        <Text fontSize="xs" ml={2} userSelect="none">
          {count}개중 {start}-{end}
        </Text>
      </Box>
    )
  }

  const search = (
    <Search
      isLoading={isLoading}
      value={params.search}
      onClear={() => {
        setPage(1)
        setParams({ ...params, page: 1, search: '' })
        setLoaded(false)
      }}
      onChange={(e) => {
        setPage(1)
        setParams({ ...params, page: 1, search: e.target.value })
        setLoaded(false)
      }}
    />
  )

  if (isLoading)
    return (
      <Box display="flex" flexDir="column" h="100%">
        {search}
        <Box
          h="100%"
          display="flex"
          flexDir="column"
          gap={3}
          overflow="auto"
          px={4}
        >
          <Skeleton opacity={0.2} minH="68px" h="68px" w="100%" rounded="md" />
          <Skeleton opacity={0.2} minH="68px" h="68px" w="100%" rounded="md" />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={4}
          gap={2}
        >
          {pageNavigation()}
          <Button
            size="sm"
            onClick={() => props.setView(View.FORM)}
            w="fit-content"
            colorScheme="purple"
          >
            {LABEL} 생성하기
          </Button>
        </Box>
      </Box>
    )

  if (noData)
    return (
      <Box display="flex" flexDir="column" h="100%">
        {search}
        <Box
          h="100%"
          px={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap={3}
          pb={12}
        >
          <Text fontSize="sm">{renderNoDataText()}</Text>
          <Button
            size="sm"
            onClick={() => props.setView(View.FORM)}
            colorScheme="purple"
          >
            {LABEL} 생성하기
          </Button>
        </Box>
      </Box>
    )
  return (
    <Box display="flex" flexDir="column" h="100%">
      {search}
      <Box
        h="410px"
        display="flex"
        flexDir="column"
        gap={3}
        overflow="auto"
        px={4}
      >
        {data.map((item) => {
          return (
            <ListItem
              key={item._id}
              onClick={() => {
                props.setView(View.FORM, item)
              }}
              title={item.name}
              actions={[
                {
                  icon: <FaRegPenToSquare />,
                  onClick: () => {
                    props.setView(View.FORM, item)
                  },
                },
                {
                  icon: <ArrowForwardIcon />,
                  onClick: () => {
                    setLocation(`${item._id}`)
                  },
                  isDefault: true,
                },
              ]}
              subtitle={`사용중인 데이터프레임: ${item.dataframe
                .map((a) => a.name)
                .join(', ')}`}
            />
          )
        })}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={4}
        gap={2}
      >
        {pageNavigation()}
        <Button
          size="sm"
          onClick={() => props.setView(View.FORM)}
          w="fit-content"
          colorScheme="purple"
        >
          {LABEL} 생성하기
        </Button>
      </Box>
    </Box>
  )
}
