import { Box, SlideFade } from '@chakra-ui/react'
import { ReactNode } from 'react'

export const ChatContainer = ({
  children,
  rounded,
}: {
  children: ReactNode
  rounded?: boolean
}) => (
  <SlideFade in={true}>
    <Box
      p={6}
      roundedTop={rounded ? 'xl' : 'none'}
    >
      <Box display="flex" gap={4} pb={2} rounded="xl">
        {children}
      </Box>
    </Box>
  </SlideFade>
)
