import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { Fragment, useEffect, useRef, useState } from 'react'
import { GoArrowRight, GoArrowUp } from 'react-icons/go'
import ReactJson from 'react-json-view'
import io from 'socket.io-client'
import { useParams } from 'wouter'
import { api } from '../../api'
import { ChatInitFunctionReturn } from '../../api/types'
import { DataTable } from '../../components/data-table'
import { StackedBarChart } from '../../components/stacked-barchart'
import { BotAvatar, UserAvatar } from './avatar'
import { ChatContainer } from './chat-container'
import { Snippet } from './snippet'

const socket = io(`${process.env.REACT_APP_SOCKET_URL}`)

const contextId = Math.random().toString(36).substring(7)

export const COLORS = [
  '#4FD1C5',
  '#63B3ED',
  '#FC8181',
  '#76E4F7',
  '#F687B3',
  '#B794F4',
  '#F6AD55',
  '#F6E05E',
  '#68D391',
]

enum ChatType {
  intro = 'Intro',
  bot = 'Bot',
  botLoading = 'Bot-loading',
  botError = 'Bot-error',
  user = 'User',
}

export default function Dashboard() {
  const sounds = useRef<{
    purr?: HTMLAudioElement
    meow?: HTMLAudioElement
  }>({
    purr: undefined,
    meow: undefined,
  })
  const [conversation, setConversation] = useState<
    {
      id: string
      type: ChatType
      content?: string | null
      createdAt?: Date
      raw?: any
    }[]
  >([{ id: `${contextId}-0`, type: ChatType.intro, createdAt: new Date() }])
  const messageRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [stats, setStats] = useState({})
  const statsDisclosure = useDisclosure()
  const params = useParams()
  const [initData, setInitData] = useState<ChatInitFunctionReturn | null>(null)

  useEffect(() => {
    socket.emit('storeUserInfo', { sessionId: contextId })
    api.chat
      .init({
        sessionId: contextId,
        channelId: params._id as string,
      })
      .then((res) => {
        setInitData(res)
      })

    sounds.current = {
      purr: new Audio('/cat-purr.wav'),
      meow: new Audio('/cat-meow.wav'),
    }
  }, [])

  const onSubmit = async (values: any, actions: any) => {
    if (!values.message || isLoading) return
    setIsLoading(true)

    if (sounds.current.purr) {
      sounds.current.purr.volume = 0.4
      sounds.current.purr.loop = true
      sounds.current.purr.play()
    }

    const newConversation = [...conversation]
    newConversation.push({
      id: `${contextId}-${conversation.length}`,
      type: ChatType.user,
      content: values.message,
      createdAt: new Date(),
    })
    newConversation.push({
      id: `${contextId}-${conversation.length}`,
      type: ChatType.botLoading,
    })

    setConversation(newConversation)

    setTimeout(() => {
      const bottomElement = document.getElementById('scroll-dummy')
      if (bottomElement) {
        bottomElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }, 100)

    if (actions) actions.resetForm()

    const newChat = await api.chat.prompt({
      index: conversation.length,
      sessionId: contextId,
      message: values.message,
      channelId: params._id as string,
    })

    if (sounds.current.purr && sounds.current.meow) {
      sounds.current.purr.pause()
      sounds.current.meow.volume = 0.2
      sounds.current.meow.play()
    }

    setConversation(() => {
      newConversation[newConversation.length - 1] = {
        id: `${contextId}-${conversation.length}`,
        type: ChatType.bot,
        content: newChat.answer,
        raw: newChat,
        createdAt: new Date(),
      }
      return newConversation
    })
    setTimeout(() => {
      const bottomElement = document.body
      bottomElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }, 100)

    setIsLoading(false)
  }

  if (!initData) return <></>

  return (
    <Box display="flex" flexDir="column" height="100%" bg="gray.800">
      <Box display="flex" flexDir="column" height="100%">
        <SlideFade in={true} style={{ height: '100%' }}>
          <Box
            bg="gray.800"
            height="100%"
            display="flex"
            flexDir="column"
            gap={4}
            p={2}
            pt={3}
            color="white"
          >
            <Box display="flex" gap={4} w="full" h="calc(100%)">
              <Box gap={4} w="full" h="100%" position="relative">
                <Box mx="auto" w="full" h="full">
                  <Snippet size="lg" variant="none">
                    <Box height="calc(100%)" overflow="auto">
                      {conversation.map((dialog, index) => {
                        const { type, content = '', createdAt, raw } = dialog
                        const parsedContent =
                          content &&
                          content.replace('```html', '').replace('```', '')

                        const formatter = new Intl.DateTimeFormat('ko-KR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        })

                        // Format the date using the formatter
                        const formattedCreatedAt = (
                          <Text fontSize="xs" color="gray.500">
                            {createdAt && formatter.format(createdAt)}
                          </Text>
                        )

                        switch (type) {
                          case ChatType.intro:
                            return (
                              <ChatContainer key={index} rounded={index === 0}>
                                <BotAvatar />
                                <Box w="full">
                                  <Box display="flex" flexDir="column">
                                    <Box
                                      className="ChatContent"
                                      display="flex"
                                      flexDir="column"
                                      gap={4}
                                      maxW={800}
                                    >
                                      <Text>{initData.intro}</Text>
                                      <Text opacity={0.4} size="2xs">
                                        다음 <b>키워드</b>를 사용해서 질문을
                                        해보세요:
                                        <br />
                                      </Text>
                                      <table>
                                        <tbody>
                                          {initData.keywords &&
                                            Object.keys(initData.keywords).map(
                                              (key) => {
                                                return (
                                                  <Box
                                                    as="tr"
                                                    display="flex"
                                                    gap={2}
                                                    key={key}
                                                    pb={4}
                                                  >
                                                    <td>
                                                      <Tag whiteSpace="nowrap">
                                                        <Text>{key}</Text>
                                                      </Tag>
                                                    </td>
                                                    <td>
                                                      <Box>
                                                        <Text>
                                                          {initData.keywords[
                                                            key
                                                          ].join(', ')}
                                                        </Text>
                                                      </Box>
                                                    </td>
                                                  </Box>
                                                )
                                              },
                                            )}
                                        </tbody>
                                      </table>
                                      <Text opacity={0.4} size="2xs">
                                        예)
                                      </Text>
                                      <Box
                                        gap={4}
                                        display="flex"
                                        w="full"
                                        flexDir="column"
                                        maxW={400}
                                      >
                                        {initData.sampleQuestions.map(
                                          (message: any, key: any) => {
                                            return (
                                              <QuestionButton
                                                key={key}
                                                label={message}
                                                onClick={() => {
                                                  onSubmit(
                                                    {
                                                      message,
                                                    },
                                                    null,
                                                  )
                                                }}
                                              />
                                            )
                                          },
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </ChatContainer>
                            )
                          case ChatType.bot:
                            try {
                              const { answers, graphs, table } = JSON.parse(
                                parsedContent || '{}',
                              )

                              return (
                                <ChatContainer
                                  key={index}
                                  rounded={index === 0}
                                >
                                  <BotAvatar />
                                  <Box w="full">
                                    <Box display="flex" flexDir="column">
                                      <Box
                                        className="ChatContent"
                                        display="flex"
                                        flexDir="column"
                                        gap={10}
                                      >
                                        {answers.map(
                                          (answer: any, key: any) => {
                                            const graphData: any = {
                                              ...(graphs?.[key] || {}),
                                            }

                                            graphData.datasets =
                                              graphData.datasets?.map(
                                                (dataset: any) => {
                                                  return {
                                                    ...dataset,
                                                    backgroundColor:
                                                      COLORS[
                                                        key % answers.length
                                                      ],
                                                  }
                                                },
                                              )

                                            return (
                                              <Box
                                                key={key}
                                                display="flex"
                                                flexDir="column"
                                                gap={2}
                                              >
                                                <Text>{answer.summary}</Text>
                                                {answer.items?.length > 0 && (
                                                  <Box
                                                    display="flex"
                                                    flexDir="column"
                                                    gap={2}
                                                    opacity={0.7}
                                                  >
                                                    {answer.items?.map(
                                                      (
                                                        item: any,
                                                        key2: any,
                                                      ) => (
                                                        <div key={key2}>
                                                          {item}
                                                        </div>
                                                      ),
                                                    )}
                                                  </Box>
                                                )}
                                                {graphData.datasets &&
                                                  graphData.datasets?.[0]?.data
                                                    ?.length > 1 && (
                                                    <Box
                                                      w="full"
                                                      maxW="800px"
                                                      maxH="200px"
                                                      rounded="xl"
                                                      overflow="hidden"
                                                      bg="blackAlpha.100"
                                                      mt={2}
                                                      key={key}
                                                    >
                                                      <StackedBarChart
                                                        data={graphData}
                                                      />
                                                    </Box>
                                                  )}
                                                {table?.[key] && (
                                                  <TableContainer maxW="800px">
                                                    <Table>
                                                      <Thead>
                                                        <Tr>
                                                          {Object.keys(
                                                            table[key][0],
                                                          ).map((key: any) => {
                                                            return (
                                                              <Td
                                                                key={key}
                                                                fontWeight={600}
                                                                opacity={0.7}
                                                              >
                                                                {key}
                                                              </Td>
                                                            )
                                                          })}
                                                        </Tr>
                                                      </Thead>
                                                      <Tbody>
                                                        {table[key].map(
                                                          (
                                                            row: any,
                                                            key: any,
                                                          ) => {
                                                            return (
                                                              <Tr key={key}>
                                                                {Object.keys(
                                                                  row,
                                                                ).map(
                                                                  (
                                                                    key: any,
                                                                    index,
                                                                  ) => {
                                                                    return (
                                                                      <Td
                                                                        key={
                                                                          key
                                                                        }
                                                                      >
                                                                        {
                                                                          row[
                                                                            Object.keys(
                                                                              row,
                                                                            )[
                                                                              index
                                                                            ]
                                                                          ]
                                                                        }
                                                                      </Td>
                                                                    )
                                                                  },
                                                                )}
                                                              </Tr>
                                                            )
                                                          },
                                                        )}
                                                      </Tbody>
                                                    </Table>
                                                  </TableContainer>
                                                )}
                                              </Box>
                                            )
                                          },
                                        )}
                                      </Box>
                                      <Box
                                        display="flex"
                                        gap={2}
                                        alignItems="center"
                                        mt={1}
                                        justifyContent="space-between"
                                      >
                                        {formattedCreatedAt}
                                        <Box
                                          display="flex"
                                          gap={2}
                                          alignItems="center"
                                        >
                                          <InfoOutlineIcon
                                            fontSize="xs"
                                            opacity={0.5}
                                            cursor="pointer"
                                            _hover={{
                                              opacity: 1,
                                            }}
                                            onClick={() => {
                                              setStats(raw)
                                              statsDisclosure.onOpen()
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </ChatContainer>
                              )
                            } catch (e) {
                              console.log(e)
                              return (
                                <ChatContainer key={index}>
                                  <BotAvatar />
                                  <Box display="flex" flexDir="column" w="full">
                                    <Box
                                      className="ChatContent"
                                      display="flex"
                                      flexDir="column"
                                      gap={6}
                                    >
                                      에러가 발생했습니다. 질문을 다르게
                                      조합해주세요!
                                    </Box>
                                    <Box
                                      display="flex"
                                      gap={2}
                                      alignItems="center"
                                      mt={1}
                                      justifyContent="space-between"
                                    >
                                      {formattedCreatedAt}
                                      <Box
                                        display="flex"
                                        gap={2}
                                        alignItems="center"
                                      >
                                        <InfoOutlineIcon
                                          fontSize="xs"
                                          opacity={0.5}
                                          cursor="pointer"
                                          _hover={{
                                            opacity: 1,
                                          }}
                                          onClick={() => {
                                            setStats(raw)
                                            statsDisclosure.onOpen()
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </ChatContainer>
                              )
                            }
                          // eslint-disable-next-line no-fallthrough
                          case ChatType.botError:
                            return (
                              <ChatContainer key={index}>
                                <BotAvatar />
                                <Box display="flex" flexDir="column">
                                  <Box
                                    className="ChatContent"
                                    display="flex"
                                    flexDir="column"
                                    gap={6}
                                    dangerouslySetInnerHTML={{
                                      __html: parsedContent || '',
                                    }}
                                  />
                                  <Box
                                    display="flex"
                                    gap={2}
                                    alignItems="center"
                                    mt={1}
                                  >
                                    {formattedCreatedAt}
                                  </Box>
                                </Box>
                              </ChatContainer>
                            )
                          case ChatType.botLoading:
                            return (
                              <ChatContainer key={index}>
                                <BotAvatar />
                                <Box>
                                  <div className="loading">
                                    {content || '고로롱고로롱'}
                                  </div>
                                  <ChatLoadingProgress
                                    contextId={contextId}
                                    id={dialog.id}
                                  />
                                </Box>
                              </ChatContainer>
                            )

                          case ChatType.user:
                            return (
                              <ChatContainer key={index}>
                                <UserAvatar />
                                <Box display="flex" flexDir="column">
                                  <Box
                                    className="ChatContent"
                                    display="flex"
                                    flexDir="column"
                                    gap={6}
                                    dangerouslySetInnerHTML={{
                                      __html: parsedContent || '',
                                    }}
                                  />
                                  <Box
                                    display="flex"
                                    gap={2}
                                    alignItems="center"
                                    mt={1}
                                  >
                                    {formattedCreatedAt}
                                  </Box>
                                </Box>
                              </ChatContainer>
                            )
                        }

                        return <Fragment key={index} />
                      })}
                      <div id="scroll-dummy" />
                    </Box>
                    <Box
                      height="72px"
                      overflow="hidden"
                      borderColor="whiteAlpha.300"
                      borderTopWidth="1px"
                    >
                      <Formik
                        initialValues={{ message: '' }}
                        onSubmit={onSubmit}
                      >
                        {(props) => {
                          return (
                            <Form>
                              <Box
                                gap={2}
                                px={2}
                                py={4}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Field name="message">
                                  {({ field }: any) => {
                                    return (
                                      <Input
                                        disabled={
                                          props.isSubmitting || isLoading
                                        }
                                        ref={messageRef}
                                        lang="ko"
                                        {...field}
                                        placeholder="참치에게 질문하세요..."
                                        borderColor="transparent"
                                        focusBorderColor="transparent"
                                        _hover={{ borderColor: 'transparent' }}
                                        autoComplete="off"
                                        type="text"
                                      />
                                    )
                                  }}
                                </Field>
                                <IconButton
                                  type={
                                    !(props.isSubmitting || isLoading)
                                      ? 'submit'
                                      : 'button'
                                  }
                                  aria-label="보내기"
                                  size="sm"
                                  fontSize="lg"
                                  bg={'white'}
                                  opacity={
                                    props.isSubmitting || isLoading ? 0.5 : 1
                                  }
                                  cursor={
                                    props.isSubmitting || isLoading
                                      ? 'not-allowed'
                                      : 'pointer'
                                  }
                                  color="black"
                                  _hover={{
                                    bg: 'gray.100',
                                  }}
                                  _focus={{
                                    bg: 'gray.200',
                                  }}
                                  icon={<GoArrowUp />}
                                />
                              </Box>
                            </Form>
                          )
                        }}
                      </Formik>
                    </Box>
                  </Snippet>
                </Box>
                <Box maxW="1616px" ml="auto" w="full" display="none">
                  <Snippet title="배차기록" size="lg" disableHeader>
                    <DataTable name="배차기록" />
                  </Snippet>
                </Box>
              </Box>
            </Box>
          </Box>
          <Modal
            isOpen={statsDisclosure.isOpen}
            onClose={statsDisclosure.onClose}
          >
            <ModalOverlay />
            <ModalContent bgColor="white">
              <ModalHeader>
                <Text fontSize="lg">상세정보</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody className="not-dark" maxH="400px" overflow="auto">
                <Box px={1}>
                  <ReactJson
                    src={stats}
                    enableClipboard={false}
                    displayObjectSize={false}
                    displayDataTypes={false}
                  />
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  onClick={async () => {
                    try {
                      if (navigator?.clipboard?.writeText) {
                        await navigator.clipboard.writeText(
                          JSON.stringify(stats, null, 2),
                        )
                      }
                    } catch (err) {
                      console.error(err)
                    }
                  }}
                >
                  복사하기
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </SlideFade>
      </Box>
    </Box>
  )
}

const QuestionButton = ({
  label,
  onClick,
}: {
  label: string
  onClick: () => void
}) => (
  <Box
    p={4}
    borderRadius="lg"
    bg="whiteAlpha.50"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    _hover={{
      cursor: 'pointer',
      bg: 'whiteAlpha.100',
    }}
    onClick={onClick}
  >
    <Text>{label}</Text>
    <Box>
      <GoArrowRight fontSize="xl" />
    </Box>
  </Box>
)

const ChatLoadingProgress = ({
  contextId,
  id,
}: {
  contextId: string
  id: string
}) => {
  const [message, setMessage] = useState<null | {
    id: string
    progress: number
    total: number
    text: string
  }>()
  useEffect(() => {
    socket.on(`progress-${contextId}`, (message) => {
      if (message.id === id) {
        setMessage(message)
      }
    })

    return () => {
      socket.off(`progress-${contextId}`)
    }
  }, [])

  if (!message) return <></>

  return (
    <Box display="flex" gap={2} alignItems="center" mt={1}>
      <Text fontSize="xs" color="gray.500">
        {message.text} ({message.progress + 1}/{message.total + 1})
        {/* {parseInt(`${((message.progress + 1) / (message.total + 1)) * 100}`)}% */}
      </Text>
    </Box>
  )
}
