import { Box, Text } from "@chakra-ui/react"
import { ReactNode } from "react"

export const Snippet = (props: {
  title?: string
  size?: 'sm' | 'lg'
  disableHeader?: boolean
  children: ReactNode
  variant?: 'filled' | 'none'
}) => {
  const {
    title,
    size = 'sm',
    children,
    disableHeader,
    variant = 'filled',
  } = props

  if (size === 'sm') {
    return (
      <Box bg="gray.900" w="full" h="full" rounded="xl">
        {title && <Box
          bg="gray.900"
          height="54px"
          overflow="hidden"
          px="6"
          roundedTop="xl"
          display="flex"
          alignItems="center"
          borderBottomColor="whiteAlpha.100"
          borderBottomWidth="1px"
          justifyContent="space-between"
        >
          <Text fontSize="lg" color="cyan.200">
            {title}
          </Text>
          <Box></Box>
        </Box>}
        <Box h="calc(100% - 54px)" px={6} py={2}>
          {children}
        </Box>
      </Box>
    )
  }

  return (
    <Box
      bg={variant === 'filled' ? 'gray.900' : 'gray.800'}
      display="flex"
      flexDir="column"
      rounded="xl"
      position="relative"
      overflow="hidden"
      h="100%"
      borderColor="whiteAlpha.300"
      borderWidth="0px"
    >
      {!disableHeader && title && (
        <Box
          height="72px"
          overflow="hidden"
          px="6"
          roundedTop="xl"
          display="flex"
          alignItems="center"
          borderBottomColor="whiteAlpha.300"
          borderBottomWidth="1px"
        >
          <Text fontSize="lg" color="cyan.200">
            {title}
          </Text>
        </Box>
      )}
      {children}
    </Box>
  )
}