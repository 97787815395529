import { Box } from '@chakra-ui/react'

const AVATAR_SIZE = 48

export const BotAvatar = () => (
  <Box
    overflow="hidden"
    rounded="xl"
    minWidth={`${AVATAR_SIZE}px`}
    height={`${AVATAR_SIZE}px`}
  >
    <img
      width={`${AVATAR_SIZE}`}
      height={`${AVATAR_SIZE}`}
      src="/hwangtae.png"
      alt="참치"
    />
  </Box>
)

export const UserAvatar = () => (
  <Box
    overflow="hidden"
    rounded="md"
    minW={`${AVATAR_SIZE}px`}
    height={`${AVATAR_SIZE}px`}
    bg="purple.400"
    color="white"
    display="flex"
    alignItems="center"
    justifyContent="center"
    fontSize="md"
    fontWeight="bold"
  >
    나
  </Box>
)
