import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import ReactJson from 'react-json-view'
import { View } from '.'
import { History } from '../../../api/types'
import { StackedBarChart } from '../../../components/stacked-barchart'
import { useView } from '../../../components/use-view'
import {
  ViewBody,
  ViewContainer,
  ViewFooter,
} from '../../../components/view-parts'
import { COLORS } from '../../channel'

export const Page = () => {
  const { setView, value } = useView<History, View>()

  const { answers, graphs } = JSON.parse(value?.answer || '[]') as any
  const [stats, setStats] = useState({})
  const statsDisclosure = useDisclosure()

  return (
    <ViewContainer>
      <ViewBody isPadded>
        <Box w="full" bg="gray.100" rounded="md" p={4} display="flex" gap={2}>
          <Text fontWeight={500}>Q:</Text> {value?.question}
        </Box>
        <Box w="full" bg="gray.100" rounded="md" p={4} display="flex" gap={2}>
          <Text fontWeight={500}>A:</Text>
          <Box display="flex" flexDir="column">
            <Box
              className="ChatContent"
              display="flex"
              flexDir="column"
              gap={10}
            >
              {answers.map((answer: any, key: any) => {
                const graphData: any = {
                  ...(graphs[key] || {}),
                }

                graphData.datasets = graphData.datasets?.map((dataset: any) => {
                  return {
                    ...dataset,
                    backgroundColor: COLORS[key % answers.length],
                  }
                })

                return (
                  <Box key={key} display="flex" flexDir="column" gap={2}>
                    <Text>{answer.summary}</Text>
                    {answer.items?.length > 0 && (
                      <Box
                        display="flex"
                        flexDir="column"
                        gap={2}
                        opacity={0.7}
                      >
                        {answer.items?.map((item: any, key2: any) => (
                          <div key={key2}>{item}</div>
                        ))}
                      </Box>
                    )}
                    {graphData.datasets &&
                      graphData.datasets?.[0]?.data?.length > 1 && (
                        <Box
                          w="full"
                          maxW="800px"
                          maxH="200px"
                          rounded="xl"
                          overflow="hidden"
                          bg="blackAlpha.100"
                          mt={2}
                          key={key}
                        >
                          <StackedBarChart data={graphData} />
                        </Box>
                      )}
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
        <Modal
          isOpen={statsDisclosure.isOpen}
          onClose={statsDisclosure.onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent bgColor="white">
            <ModalHeader>
              <Text fontSize="lg">상세정보</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody className="not-dark" maxH="400px" overflow="auto">
              <Box px={1}>
                <ReactJson
                  src={stats}
                  enableClipboard={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                onClick={async () => {
                  try {
                    if (navigator?.clipboard?.writeText) {
                      await navigator.clipboard.writeText(
                        JSON.stringify(stats, null, 2),
                      )
                    }
                  } catch (err) {
                    console.error(err)
                  }
                }}
              >
                복사하기
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ViewBody>
      <ViewFooter
        left={
          <Button
            size="sm"
            onClick={() => setView(View.TABLE)}
            w="fit-content"
            bg="white"
          >
            뒤로가기
          </Button>
        }
        right={
          <Button
            size="sm"
            onClick={() => {
              setStats(JSON.stringify(value))
              statsDisclosure.onOpen()
            }}
            w="fit-content"
          >
            상세정보
          </Button>
        }
      />
    </ViewContainer>
  )
}
