import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
      color: '#A0AEC0',
    },
    legend: {
      labels: {
        color: '#A0AEC0', // Change the color of legend labels to blue
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: '#A0AEC0', // Change the color of X-axis labels
      },
      grid: {
        color: 'rgba(255,255,255,0.08)', // Change the color of X-axis grid lines
      },
    },
    y: {
      stacked: true,
      ticks: {
        color: '#A0AEC0', // Change the color of X-axis labels
      },
      grid: {
        color: 'rgba(255,255,255,0.08)', // Change the color of X-axis grid lines
      },
    },
  },
}

const labels = ['5월', '6월', '7월', '8월', '9월', '10월']

export const data = {
  labels,
  datasets: [
    {
      label: '접수',
      data: [],
      // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      color: '#A0AEC0',
      backgroundColor: '#E6FFFA',
    },
    {
      label: '배차',
      data: [],
      // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: '#81E6D9',
    },
    {
      label: '왓',
      data: [],
      // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: '#2C7A7B',
    },
  ],
}

export function StackedBarChart(props: { data?: any; options?: any }) {
  return <Bar options={options} data={props.data || data} />
}
