import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'

export const ConfirmButton = (props: {
  onClick: () => void
  children: React.ReactNode
  alertTitle: string
  alertLabel: string
  isDisabled?: boolean
}) => {
  const { onClick, children, alertTitle, alertLabel, isDisabled } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef<any>()

  return (
    <>
      <Button
        colorScheme="gray"
        size="sm"
        onClick={onOpen}
        isDisabled={isDisabled}
      >
        {children}
      </Button>

      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              {alertTitle}
            </AlertDialogHeader>

            <AlertDialogBody fontSize="xs">{alertLabel}</AlertDialogBody>

            <AlertDialogFooter>
              <Button size="sm" ref={cancelRef} onClick={onClose} bg="white">
                취소
              </Button>
              <Button
                size="sm"
                colorScheme="purple"
                onClick={() => {
                  onClick()
                  onClose()
                }}
                ml={2}
              >
                확인
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
