import {
  Box,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useState } from 'react'

export const DataTable = (props: { name: string }) => {
  const [headers, setHeaders] = useState([])
  const [data, setData] = useState([])
  const [name, setName] = useState('배차기록')

  // useEffect(() => {
  //   const getHeader = async () => {
  //     const response = await fetch(`/api/data/header?name=${name}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })

  //     const data = await response.json()

  //     setHeaders(data.data.response)
  //   }
  //   const getData = async () => {
  //     const response = await fetch(`/api/data?name=${name}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })

  //     const data = await response.json()
  //     const parsedData = data.data.response

  //     setData(parsedData)
  //   }
  //   getHeader()
  //   getData()
  // }, [name])

  return (
    <Box position="relative" display="flex" flexDir="column" h="100%">
      <Box
        bg="gray.900"
        height="72px"
        overflow="hidden"
        px="6"
        roundedTop="xl"
        display="flex"
        alignItems="center"
        borderBottomColor="whiteAlpha.100"
        borderBottomWidth="1px"
        justifyContent="space-between"
      >
        <Text fontSize="lg" color="cyan.200">
          데이터프레임
        </Text>
        <Box>
          <Select
            placeholder="데이터프레임"
            size="sm"
            rounded="md"
            borderColor="gray.400"
            color="gray.400"
            onChange={(e) => {
              setName(e.target.value)
            }}
          >
            <option value="배차기록">배차기록</option>
            <option value="고객사기록">고객사기록</option>
            <option value="직원기록">직원기록</option>
          </Select>
        </Box>
      </Box>
      <Box overflowY="auto" h="calc(100% - 144px)" position="relative">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                {headers.map((name) => {
                  return (
                    <Th
                      key={name}
                      color="gray.400"
                      borderBottomColor="whiteAlpha.100"
                    >
                      {name}
                    </Th>
                  )
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row: any[], key) => {
                return (
                  <Tr key={key}>
                    {row.map((col, key2) => {
                      return (
                        <Td
                          key={key2}
                          borderBottomColor="whiteAlpha.100"
                          fontSize="sm"
                        >
                          {col || '-'}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        bg="gray.900"
        height="72px"
        overflow="hidden"
        px="8"
        roundedBottom="xl"
        display="flex"
        alignItems="center"
        borderTopColor="whiteAlpha.100"
        borderTopWidth="1px"
      >
        {/* <Text fontSize="lg" color="cyan.200">
          데이터프레임
        </Text> */}
      </Box>
    </Box>
  )
}
