import { Box, Button, Input, Select, Text } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useState } from 'react'
import { View } from '.'
import { api } from '../../../api'
import { User } from '../../../api/types'
import { ConfirmButton } from '../../../components/confirm-button'
import { useNotification } from '../../../components/use-notification'
import { useView } from '../../../components/use-view'
import {
  ViewBody,
  ViewContainer,
  ViewFooter,
} from '../../../components/view-parts'

export const Form = () => {
  const { value, setView } = useView<User>()
  const notify = useNotification()
  const [isRemoving, setIsRemoving] = useState(false)
  const [isCreatingAgain, setIsCreatingAgain] = useState(false)
  const formik = useFormik({
    initialValues: value || {
      name: '',
      email: '',
      role: '일반사용자',
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (value) {
        const { error } = await api.user.edit(values)

        if (error) {
          formik.setErrors(error)
        } else {
          notify(`팀원, "${value.name}", 님이 업데이트됬습니다!`)
        }
      } else {
        const { error } = await api.user.add(values)

        if (error) {
          formik.setErrors(error)
        } else {
          if (isCreatingAgain) {
            formik.resetForm()
            setIsCreatingAgain(false)
          } else {
            setView(View.TABLE)
          }
          notify(`팀원, "${values.name}", 님을 초대하였습니다`)
        }
      }
    },
  })

  const renderSaveText = () => {
    if (value) return formik.isSubmitting ? '수정하는중...' : '수정하기'

    return formik.isSubmitting && !isCreatingAgain
      ? '초대하는중...'
      : '초대하기'
  }

  const renderReSaveText = () => {
    return formik.isSubmitting && isCreatingAgain
      ? '초대하는중...'
      : '저장하고 또 초대하기'
  }

  const isDisabled = formik.isSubmitting || isRemoving

  return (
    <ViewContainer>
      <Box
        as="form"
        onSubmit={formik.handleSubmit}
        h="100%"
        display="flex"
        flexDir="column"
      >
        <ViewBody isPadded>
          <Box display="flex" flexDir="column" gap={1}>
            <Text fontSize="xs" color="black" w="full">
              이름
            </Text>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder="예) 김민수"
              name="name"
              autoComplete="off"
              color="black"
              border="none"
              bg="gray.100"
              fontSize="xs"
            />
            <Text fontSize="2xs" color="red.500" h="12px">
              {formik.errors.name}
            </Text>
          </Box>
          <Box display="flex" flexDir="column" gap={1}>
            <Text fontSize="xs" color="black" w="full">
              이메일
            </Text>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="예) mskim@samillogistics.com"
              name="email"
              autoComplete="off"
              color="black"
              border="none"
              bg="gray.100"
              fontSize="xs"
              type="email"
            />
            <Text fontSize="2xs" color="red.500" h="12px">
              {formik.errors.email}
            </Text>
          </Box>
          <Box display="flex" flexDir="column" gap={1}>
            <Text fontSize="xs" color="black" w="full">
              역활
            </Text>
            <Select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
              placeholder="역활을 선택해주세요"
              name="role"
              autoComplete="off"
              color="black"
              border="none"
              bg="gray.100"
              fontSize="xs"
            >
              <option value="운영관리자">운영관리자</option>
              <option value="데이터관리자">데이터관리자</option>
              <option value="에디터">에디터</option>
              <option value="일반사용자">일반사용자</option>
            </Select>
            <Text fontSize="2xs" color="red.500" h="12px">
              {formik.errors.email}
            </Text>
          </Box>
        </ViewBody>
        <ViewFooter
          left={
            <Button
              size="sm"
              onClick={() => setView(View.TABLE)}
              w="fit-content"
              colorScheme="gray"
              bg="white"
              isDisabled={isDisabled}
            >
              뒤로가기
            </Button>
          }
          right={
            <>
              {value?._id && (
                <ConfirmButton
                  isDisabled={isDisabled}
                  onClick={() => {
                    if (value?._id) {
                      setIsRemoving(true)
                      api.user.remove({ _id: value._id }).then(() => {
                        setView(View.TABLE)
                        setIsRemoving(false)

                        notify(`팀원, "${value?.name}", 님이 삭제됬습니다.`)
                      })
                    }
                  }}
                  alertTitle={`팀원, "${value?.name}", 님을 삭제할까요?`}
                  alertLabel={`팀원을 삭제하면, 팀원에 저장된 모든 데이터가 삭제됩니다.`}
                >
                  {isRemoving ? '삭제중...' : '삭제하기'}
                </ConfirmButton>
              )}
              {!value?._id && (
                <Button
                  onClick={() => {
                    setIsCreatingAgain(true)
                    formik.submitForm()
                  }}
                  size="sm"
                  w="fit-content"
                  colorScheme="purple"
                  isDisabled={isDisabled}
                >
                  {renderReSaveText()}
                </Button>
              )}
              <Button
                onClick={formik.submitForm}
                size="sm"
                w="fit-content"
                colorScheme="purple"
                isDisabled={isDisabled}
              >
                {renderSaveText()}
              </Button>
            </>
          }
        />
      </Box>
    </ViewContainer>
  )
}
